<template>
  <div style="position: relative">
    <div
      v-if="tooltipPosition && tooltipObj"
      class="tooltip"
      :style="{
        top: tooltipPosition.y - 12 + 'px',
        left: tooltipPosition.x + 12 + 'px',
      }"
    >
      <h4 class="tooltip__title">
        {{ tooltipObj.count.toLocaleString() }}
      </h4>
    </div>

    <svg :viewBox="`0 0 ${width} ${height}`" class="binhphuoc-map">
      <defs>
        <pattern
          id="smallGrid"
          class="pattern"
          width="5"
          height="5"
          patternUnits="userSpaceOnUse"
        >
          <path d="M 5 0 L 0 0 0 5" fill="none" stroke-width="0.5" />
        </pattern>

        <pattern
          id="grid"
          class="pattern"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <rect width="20" height="20" fill="url(#smallGrid)" />

          <path d="M 20 0 L 0 0 0 20" fill="none" stroke-width="1" />
        </pattern>

        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" stdDeviation="0,0" />
        </filter>
      </defs>

      <rect width="100%" height="100%" fill="url(#grid)" />

      <g>
        <path
          v-for="p in districts"
          :id="p.id"
          :key="p.id"
          :d="p.d"
          :fill="p.fill"
          class="district"
          @mouseover.stop="mouseover($event, p)"
          @mouseleave="mouseleave"
        />
      </g>

      <g>
        <text
          v-for="p in districts"
          :key="p.id"
          :x="p.center[0]"
          :y="p.center[1]"
          text-anchor="middle"
        >
          {{ p.name }}
        </text>
      </g>
    </svg>

    <loading :active="loading" overlay />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import { geoMercator, geoPath, scaleThreshold } from "d3";
import debounce from "lodash.debounce";

export default {
  components: { Loading },
  props: {
    data: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: 501,
    },
    height: {
      type: Number,
      default: 501,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    mapping: {
      type: Object,
      default: function () {
        return {
          "Thành phố Đồng Xoài": "binhphuoc_dongxoai",
          "Thị xã Bình Long": "binhphuoc_binhlong",
          "Huyện Bù Gia Mập": "binhphuoc_bugiamap",
          "Huyện Lộc Ninh": "binhphuoc_locninh",
          "Huyện Bù Đốp": "binhphuoc_budop",
          "Huyện Hớn Quản": "binhphuoc_honquan",
          "Huyện Đồng Phú": "binhphuoc_dongphu",
          "HUYỆN BÙ ĐĂNG": "binhphuoc_budang",
          "UBND huyện Bù Đăng": "binhphuoc_budang",
          "Huyện Chơn Thành": "binhphuoc_chonthanh",
          "Chơn Thành - Thị trấn": "binhphuoc_chonthanh",
          "Thị xã Phước Long": "binhphuoc_phuoclong",
        };
      },
    },
  },
  data() {
    return {
      binhphuoc: {},
      tooltipObj: null,
      tooltipPosition: null,
    };
  },
  computed: {
    fill() {
      return scaleThreshold()
        .domain(this.districtRange)
        .range([
          "#93D24F",
          "#ffc100",
          "#ff9a00",
          "#ff7400",
          "#ff4d00",
          "#ff0000",
          "#cc3300",
        ]);
    },
    districtCounts() {
      const value = {};

      this.data.data.data.forEach((item) => {
        value[this.mapping[item.key]] = value[this.mapping[item.key]]
          ? value[this.mapping[item.key]] + item.count
          : item.count;
      });

      return Object.values(value)
        .map((c) => c)
        .sort((a, b) => a - b);
    },
    districtRange() {
      const values = this.districtCounts;
      if (!values.length) {
        return [];
      }

      const upper = values[values.length - 1];
      const lower = values[0];
      const diff = upper - lower;
      const range = [];

      if (values.length > 6) {
        return this.k_means1(values, 6)
          .map((m) => m.val)
          .sort((a, b) => a - b);
      }

      for (let i = 0; i < 6; i++) {
        range.push(lower + i * Math.floor(diff / 5));
      }
      return range;
    },
    districts() {
      if (!this.binhphuoc) {
        return {};
      }

      const value = {};

      if (this.data.data && this.data.data.data) {
        this.data.data.data.forEach((item) => {
          value[this.mapping[item.key]] = value[this.mapping[item.key]]
            ? value[this.mapping[item.key]] + item.count
            : item.count;
        });
      }

      return (this.binhphuoc.features || []).reduce((acc, feature) => {
        if (!this.data.data) {
          return acc;
        }

        // pretend that we have map[x] belong to y

        const id = feature.id;
        const count = value[id] ? value[id] : 0;
        const center = this.pathFactory.centroid(feature);

        if (id === "ttdl") {
          center[1] += 15;
        }
        acc[id] = {
          id,
          center,
          d: this.pathFactory(feature),
          fill: this.fill(count),
          name: feature.properties.name,
          count,
        };

        return acc;
      }, {});
    },
    projection() {
      return geoMercator()
        .scale(18000)
        .center([106.9, 11.7])
        .translate([this.width / 2, this.height / 2]);
    },
    pathFactory() {
      return geoPath().projection(this.projection);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    k_means1(x, n, means) {
      // A simple average function, just because
      // JavaScript doesn't provide one by default.
      function avg(x) {
        var s = 0;
        for (var i = 0; i < x.length; i++) {
          s += x[i];
        }
        return x.length > 0 ? s / x.length : 0;
      }

      // n is the number of means to choose.
      if (n === 0) {
        throw new Error("The number of means must be non-zero");
      } else if (n > x.length) {
        throw new Error(
          "The number of means must be fewer than the length of the dataset"
        );
      }

      var seen = {};

      if (!means) {
        means = [];
        // Randomly choose k means from the data and make sure that no point
        // is chosen twice. This bit inspired by polymaps
        while (means.length < n) {
          var idx = Math.floor(Math.random() * (x.length - 1));
          if (!seen[idx]) {
            means.push({ val: x[idx], vals: [] });
            seen[idx] = true;
          }
        }
      }

      var i;
      // For every value, find the closest mean and add that value to the
      // mean's `vals` array.
      for (i = 0; i < x.length; i++) {
        var dists = [];
        for (var j = 0; j < means.length; j++) {
          dists.push(Math.abs(x[i] - means[j].val));
        }
        var closestIndex = dists.indexOf(Math.min.apply(null, dists));
        means[closestIndex].vals.push(x[i]);
      }

      // Create new centers from the centroids of the values in each
      // group.
      //
      // > In the case of one-dimensional data, such as the test scores,
      // the centroid is the arithmetic average of the values
      // of the points in a cluster.
      //
      // [Vance Faber](http://bit.ly/LHCh2y)
      var newvals = [];
      for (i = 0; i < means.length; i++) {
        var centroid = avg(means[i].vals);
        newvals.push({
          val: centroid,
          vals: [],
        });
      }

      return newvals;
    },
    mouseover: debounce(
      function ($event, item) {
        this.updatePosition($event);
        this.tooltipObj = item;
      },
      75,
      { maxWait: 500 }
    ),
    mouseleave: debounce(
      function () {
        this.updatePosition();
      },
      75,
      { maxWait: 500 }
    ),
    updatePosition($event) {
      if (!$event) {
        this.tooltipPosition = null;
        return;
      }

      const rect = this.$el.getBoundingClientRect();
      this.tooltipPosition = {
        x: $event.pageX - (rect.left + (window.scrollX || window.pageXOffset)),
        y: $event.pageY - (rect.top + (window.scrollY || window.pageYOffset)),
      };
    },
    init() {
      const binhphuoc = () => import("@/assets/binhphuoc.json");
      binhphuoc().then((data) => {
        const binhphuoc = data.default;
        this.binhphuoc = binhphuoc;
      });
    },
  },
};
</script>

<style lang="scss">
.binhphuoc-map {
  background-color: #1f2128;

  text {
    font-size: 11px;
    fill: #fff;
    text-shadow: 1px 1px 2px black;
  }
}

.district {
  opacity: 0.8;
  stroke: #411834;
  stroke-width: 0.6;
  transition: fill 150ms linear;

  &:hover {
    fill: #f8aa0f;
    opacity: 1;
  }
}
</style>
