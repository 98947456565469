<template>
  <div class="range-picker-advanced">
    <popover :open.sync="dropdown" :disabled="disabled" :should-close="shouldClose" fixed
      placement="bottom-end">
      <button slot="trigger" :class="{ button: true, 'is-small': small, 'is-static': disabled }"
        type="button">
        <octicon :icon="calendar" />
        <span>{{ text }}</span>
      </button>
      <tabs :show.sync="active" :theme="$_ui_theme_tabs">
        <tab :title="$t('picker.presets')">
          <div class="columns">
            <div v-for="(chunk, index) in suggestionsChunks" :key="index" class="column">
              <a v-for="s in chunk" :key="s.key" :class="{
                'dropdown-item': true,
                'is-active': suggestion && s.key === suggestion.key
              }" href="#" @click.prevent="choose(s)">{{ $t('picker.' + s.key) }}</a>
            </div>
          </div>
        </tab>
        <tab :title="$t('picker.range')">
          <input ref="rp" v-rangepicker="dates" type="text" class="input">
        </tab>
      </tabs>
    </popover>
  </div>
</template>
<script>
import { chunk } from '@/utils'
import { Tab, Tabs } from '@cyradar/ui'
import {
  endOfDay,
  endOfMonth,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
  subMinutes,
  subMonths
} from 'date-fns'
import Flatpickr from 'flatpickr'
import { calendar } from 'octicons-vue'

export default {
  i18n: {
    dateTimeFormats: {
      en: {
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      vi: {
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      }
    },
    messages: {
      en: {
        picker: {
          pick: 'Pick a range',
          presets: 'Presets',
          range: 'Date & Time Picker',
          day0: 'Today',
          'day-1': 'Yesterday',
          'day-7': 'Last 7 days',
          'day-30': 'Last 30 days',
          month0: 'This month',
          'month-1': 'Last month',
          'minute-5': 'Last 5 minutes',
          'minute-15': 'Last 15 minutes',
          'minute-30': 'Last 30 minutes',
          'hour-1': 'Last hour',
          'hour-4': 'Last 4 hours',
          'hour-12': 'Last 12 hours',
          'hour-24': 'Last 24 hours',
          'month-3': 'Last 3 months',
          'month-6': 'Last 3 months'
        }
      },
      vi: {
        picker: {
          pick: 'Khoảng thời gian',
          presets: 'Gợi ý',
          range: 'Ngày và Giờ',
          day0: 'Hôm nay',
          'day-1': 'Hôm qua',
          'day-7': '7 ngày trước',
          'day-30': '30 ngày trước',
          month0: 'Tháng này',
          'month-1': 'Tháng trước',
          'minute-5': '5 phút trước',
          'minute-15': '15 phút trước',
          'minute-30': '30 phút trước',
          'hour-1': '1 giờ trước',
          'hour-4': '4 giờ trước',
          'hour-12': '12 giờ trước',
          'hour-24': '24 giờ trước',
          'month-3': '3 tháng trước',
          'month-6': '6 tháng trước'
        }
      }
    }
  },
  components: { Tabs, Tab },
  directives: {
    rangepicker: {
      bind(el, binding, vnode) {
        el.picker = new Flatpickr(el, {
          defaultDate: binding.value,
          mode: 'range',
          enableTime: true,
          time_24hr: true,
          onChange(val) {
            if (!val || val.length !== 2) {
              return
            }

            vnode.context.suggestion = null
            vnode.context.dates = [val[0], val[1]]
          }
        })
      },
      unbind(el) {
        if (!el.picker) {
          return
        }

        el.picker.destroy()
      }
    }
  },
  props: {
    lookup: {
      type: String,
      default: ''
    },
    range: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: 0,
      dropdown: false,
      suggestion: null,
      dates: null,
      now: new Date()
    }
  },
  computed: {
    text() {
      if (this.type === 'presets') {
        return this.$t(`picker.${this.suggestion.key}`)
      }

      if (this.type === 'range') {
        return (
          this.$d(this.dates[0], 'short') +
          ' - ' +
          this.$d(this.dates[1], 'short')
        )
      }

      return this.$t('picker.pick')
    },
    type() {
      if (this.suggestion && this.suggestion.key) {
        return 'presets'
      }

      if (this.dates && this.dates.length === 2) {
        return 'range'
      }

      return ''
    },
    calendar() {
      return calendar
    },
    suggestions() {
      const today = this.now
      const yesterday = subDays(today, 1)
      const daysAgo7 = subDays(today, 7)
      const daysAgo30 = subDays(today, 30)
      const lastMonth = subMonths(today, 1)
      const last3Month = subMonths(today, 3)
      const last6Month = subMonths(today, 6)

      return [
        { key: 'day0', value: [startOfDay(today), endOfDay(today)] },
        { key: 'day-1', value: [startOfDay(yesterday), endOfDay(yesterday)] },
        { key: 'day-7', value: [startOfDay(daysAgo7), endOfDay(today)] },
        { key: 'day-30', value: [startOfDay(daysAgo30), endOfDay(today)] },
        { key: 'month0', value: [startOfMonth(today), endOfMonth(today)] },
        {
          key: 'month-1',
          value: [startOfMonth(lastMonth), endOfMonth(lastMonth)]
        },
        {
          key: 'month-3',
          value: [startOfMonth(last3Month), endOfMonth(today)]
        },
        {
          key: 'month-6',
          value: [startOfMonth(last6Month), endOfMonth(today)]
        },
        { key: 'minute-5', value: [subMinutes(today, 5), today] },
        { key: 'minute-15', value: [subMinutes(today, 15), today] },
        { key: 'minute-30', value: [subMinutes(today, 30), today] },
        { key: 'hour-1', value: [subHours(today, 1), today] },
        { key: 'hour-4', value: [subHours(today, 4), today] },
        { key: 'hour-12', value: [subHours(today, 12), today] },
        { key: 'hour-24', value: [subHours(today, 24), today] }
      ]
    },
    suggestionsChunks() {
      return chunk(this.suggestions, 6)
    },
    containsStrings() {
      if (!this.range || !this.range.length) {
        return false
      }

      return typeof this.range[0] === 'string'
    }
  },
  watch: {
    lookup: {
      immediate: true,
      handler: 'setSuggestionFromLookup'
    },
    range: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }

        if (!this.containsStrings) {
          this.dates = val
          return
        }

        this.dates = val.map((x) => new Date(x))
      }
    },
    suggestion: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.$emit('update:lookup', '')
          return
        }

        this.$emit('update:lookup', val.key)
        this.dates = val.value
      }
    },
    dates: {
      immediate: true,
      handler(val) {
        this.$emit('update:range', val)
      }
    }
  },
  methods: {
    reloadSuggestions() {
      this.now = new Date()
      this.setSuggestionFromLookup()
    },
    setSuggestionFromLookup() {
      const item = this.suggestions.find(
        (suggestion) => suggestion.key === this.lookup
      )
      if (!item) {
        this.suggestion = null
        return
      }

      this.suggestion = item
    },
    choose(suggestion) {
      if (!suggestion) {
        return
      }

      this.suggestion = suggestion
      this.dropdown = false
    },
    shouldClose(e) {
      if (this.$el.contains(e.target)) {
        return false
      }

      if (
        !this.$refs.rp ||
        !this.$refs.rp.picker ||
        !this.$refs.rp.picker.calendarContainer
      ) {
        return true
      }

      if (this.$refs.rp.picker.calendarContainer.contains(e.target)) {
        return false
      }

      if (e.target.tagName === 'BODY' && this.$refs.rp.picker.isOpen) {
        // sometimes clicking into the input fires events whose target is body
        return false
      }

      return true
    }
  }
}
</script>
<style lang="scss">
.range-picker-advanced {
  .dropdown-menu {
    min-width: 30rem;
  }
}
</style>
